<template>
    <div class="sup_content language-list">
        <div class="sup_page_heading">
            <h2>Deactive Websites</h2>
        </div>
    <div class="sup_inner_content">
    <div class="new_dataTable_wrapper">
        <div class="device_table_top">
            <div class="deviceSearch">
                <div class="text_wrapper active_website_search">
                    <!-- <i class="pi pi-search"></i> -->
                    <Calendar v-model="allDeactiveData.dateRange" placeholder="DD.MM.YY" dateFormat="dd.mm.yy" 
                    selectionMode="range" class="sup_button p-ml-2" inputClass="p-inputtext" :manualInput="false" 
                    :showIcon="true" @date-select="allDeactiveData.dateRange[1] != null ? fetchActiveWebsite('dateRange') : ''" plceholder="abc" />
                    <Button label="Clear" class="p-button-raised p-button-warning p-ml-2" @click="fetchActiveWebsite('resetFilters')" />
                    
                </div>
            </div>
            <Button v-if="allDeactiveData.data" label="Export" icon="pi pi-external-link" class="p-button-secondary p-ml-2" @click="exportXL('excel')" />
        </div>
        <div class="device_table_panel" v-if="allDeactiveData.isShow">
            <table>
                <thead>
                    <tr>
                        <th>Website</th>
                        <th>Customer</th>
                        <th>Current Hits</th>
                        <th>Is Free</th>
                        <th>Last updated on</th>
                    </tr>
                </thead>
                <tbody v-if="allDeactiveData.total === 0">
                    <tr>
                        <td colspan="8">No Data Available</td>
                    </tr>
                </tbody>
                <tbody v-else>
                    <tr v-for="data of allDeactiveData.data" :key="data">
                        <td>
                            <span class="sup_site_info p-d-block">{{data.siteName ? data.siteName : 'NA'}}</span>
                            <a :href="'https://' + [data.siteUrl ? data.siteUrl : 'NA']" class="sup_website_url" target="_blank">{{data.siteUrl ? data.siteUrl : 'NA'}}</a>
                        </td>
                        <td>
                            <span class="sup_site_info p-d-block">{{data.User.name ? data.User.name : 'NA'}}</span>
                            <span class="sup_site_info p-d-block">{{data.User.email ? data.User.email : 'NA'}}</span>
                        </td>
                        <td>{{data.Websitehit ? data.Websitehit.currentHits : 'NA'}}</td>
                        <td>{{data.License ? data.License.isFree === true ? 'Yes' : 'No' : 'NA'}}</td>
                        <td>{{data.Websitehit ? usFormatDate(data.Websitehit.updatedAt) : 'NA'}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    </div>
    </div>
</template>
<script>
import { deactiveWebsites, deactiveWebsiteExport } from '../../../../config/appUrls';
import { apiKey } from "../../../../config/constant";
import { formatDate, usFormatDate } from '../../../../helper/utility/utility';
import axios from "axios";
import { useStore } from 'vuex';
const FileSaver = require('file-saver');
import { reactive, onBeforeMount } from 'vue';
export default {
    name: 'DeactiveWebsites',
    setup(){
        //Route call        
        const store = useStore();

        const allDeactiveData = reactive({
            data : null,
            loading : false,
            dateRange : null,
            activityStatus: 'all',
            total: null,
            isShow: false
        });

        const fetchActiveWebsite = async (load) =>{
            allDeactiveData.isShow = true;
            let date = null;

            //To clear search and filter then fetch all data
            if(load === 'resetFilters'){
                allDeactiveData.dateRange = null;
                allDeactiveData.data = null;
                allDeactiveData.isShow =false
            } else {
                // Query last 30 days updated Website hit data if no date range selected
                let today = new Date();
                let lastMonthToday = new Date();
                lastMonthToday.setMonth(lastMonthToday.getMonth()-1);
                date = formatDate(lastMonthToday.toISOString().substring(0,10)) + ' ' + formatDate(today.toISOString().substring(0,10));
            }

            //Filter date wise code
            if (load === 'dateRange') {
                date = formatDate(allDeactiveData.dateRange[0]) + ' ' + formatDate(allDeactiveData.dateRange[1]);
            }

            //API call start
            try{
                allDeactiveData.loading = !allDeactiveData.loading;
                const response = await axios.get( deactiveWebsites, {
                    params: {
                        filterDt: date
                    },
                    headers: {
                        apiKey: apiKey,
                        token: store.getters.adminAuthToken
                    }, 
                });
                allDeactiveData.data = response.data.data.websites;
                allDeactiveData.total = response.data.data.total;
                allDeactiveData.loading = !allDeactiveData.loading;
            } catch(err){
                console.log('error', err);
            }
        }

        const exportXL = async(load) =>{
            let date = null;
            if (load === 'excel') {
                try{
                    if (allDeactiveData.dateRange !== null) {
                        date = formatDate(allDeactiveData.dateRange[0]) + ' ' + formatDate(allDeactiveData.dateRange[1]);
                    }
                    const response = await axios.get( deactiveWebsiteExport, {
                        responseType: 'arraybuffer',
                        params: {
                            filterDt: date,
                            exe: load
                        },headers: {
                            apiKey: apiKey,
                            token: store.getters.adminAuthToken
                        },
                    });
                    if(response.status === 200) {
                        const dirtyFileName = response.headers['content-disposition'];
                        const regex = /filename[^;=\n]*=(?:(\\?['"])(.*?)\1|(?:[^\s]+'.*?')?([^;\n]*))/;
                        var fileName = dirtyFileName.match(regex)[3];
                        var blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                        FileSaver(blob, fileName);
                    }
                } catch(err) {
                    console.log('error', err);
                }   
            } else {
                try{
                    if (allDeactiveData.dateRange !== null) {
                        date = formatDate(allDeactiveData.dateRange[0]) + ' ' + formatDate(allDeactiveData.dateRange[1]);
                    }
                    const response = await axios.get( deactiveWebsiteExport, {
                        responseType: 'arraybuffer',
                        params: {
                            filterDt: date,
                            exe: load
                        },headers: {
                            apiKey: apiKey,
                            token: store.getters.adminAuthToken
                        },
                    });
                    if(response.status === 200) {
                        const dirtyFileName = response.headers['content-disposition'];
                        const regex = /filename[^;=\n]*=(?:(\\?['"])(.*?)\1|(?:[^\s]+'.*?')?([^;\n]*))/;
                        var pdfFileName = dirtyFileName.match(regex)[3];
                        var pdfBlob = new Blob([response.data], { type: 'application/pdf' });
                        FileSaver(pdfBlob, pdfFileName);
                    }
                } catch(err) {
                    console.log('error', err);
                }   
            }
        }

        onBeforeMount(async() => {
            fetchActiveWebsite();
        });

        //Returing variables to template
        return{
            allDeactiveData,
            fetchActiveWebsite,
            usFormatDate,
            exportXL
        }
    }
}
</script>
<style  lang="scss" scoped>
@import '../../../../assets/style/component/superAdmin/pages/widgetManagement/DeviceInfo';

.sup_website_info_section {
  border: 1px solid #5385e2;
  margin-bottom: 15px;
  border-radius: 5px;
}

.sup_website_info_section .sup_section_title {
  background-color: #5385e2;
  padding: 5px 10px;
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  color: #ffffff;
  line-height: 1.4;
  font-weight: 600;
}

.sup_website_info_section .sup_section_title i.pi {
  margin-right: 5px;
}

.sup_website_info_section ul {
  padding: 10px 15px;
  list-style: none;
}

.sup_website_info_section ul li {
  font-size: 15px;
  line-height: 1.2;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 10px;
}

.sup_website_info_section ul li strong {
  -ms-flex-negative: 0;
      flex-shrink: 0;
  width: 200px;
  text-align: left;
  display: inline-block;
  margin-right: 15px;
  color: #333333;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
}


</style>
